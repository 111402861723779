import { render, staticRenderFns } from "./proposalResolution.vue?vue&type=template&id=15e11b0d&scoped=true"
import script from "./proposalResolution.vue?vue&type=script&lang=js"
export * from "./proposalResolution.vue?vue&type=script&lang=js"
import style0 from "./proposalResolution.vue?vue&type=style&index=0&id=15e11b0d&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e11b0d",
  null
  
)

export default component.exports